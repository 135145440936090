import Vue from 'vue'
import Router from 'vue-router'
import home from "../pages/Home.vue"

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: home
    },
    {
        path:'/theFigure',
        name: 'theFigure',
        component: () => import("../pages/theFigure.vue")
    },
    {
      path:'/peopleMove',
      name: 'peopleMove',
      component: () => import("../pages/peopleMove.vue")
    },
    {
      path:'/coil',
      name: 'coil',
      component: () => import("../pages/coil/index.vue")
    },
    {
      path:'/funnel',
      name: 'funnel',
      component: () => import("../pages/funnel/index.vue")
    },
    {
      path:'/clone',
      name: 'clone',
      component: () => import("../pages/clone/index.vue")
    }
  ],
})

