<template>
  <div ref="threeDiv" class="threeDiv">
    
  </div>
</template>

<script>
import * as THREE from 'three';
//加载控制器
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
//判断浏览器支不支持3D
import { Detector } from "@/utils/detector/Detector";
//透视相机
import { PerspectiveCamera } from "three/src/cameras/PerspectiveCamera"
import { Vector2 } from "three/src/math/Vector2"
import { Raycaster } from "three/src/core/Raycaster"
//加载GBL和GLTF格式的类
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
export default {
  name: 'threeModel',
  props:{
    //模型的绝对路径地址
    path:{
      type:String
    },
    //模型的名称 比如模型为 object.gbl
    pathName:{
      type:String
    },
    //需要传入的数据 数据格式参考uitls/jsonData.js
    objJson:{
      type:Object,
      default:{}
    }
  },
  data(){
    return {
      //鼠标的xy
      mouse:null,
      //全局相机对象
      camera:null,
      //全局场景
      scene:null,
      //全局渲染器
      renderer:null,
      //存储threeDiv的dom对象
      container:null,
      //全局控制器
      controls:null,
      raycaster:null,
      count:1,
      //储存objJson数据
      jsonData:{},
      //记录鼠标选中数据，下次点击先清空当前数据中的children
      selectedData:[],
      //摄像机角度
      fov:45,
      //摄像机锥体近距离
      near:1,
      //摄像机锥体远距离
      far:2000,
    }
  },
  watch:{
    //监听objJson是否变化，并且模型路径和路径名称是否有，如果有，初始化3D
    objJson:{
      handler:function(){
        //深拷贝objJson，防止修改父组件传过来的参数报错问题
        this.jsonData=JSON.parse(JSON.stringify(this.objJson));
        //判断是否有模型路径和路径名称，为true初始化
        if(this.path&&this.pathName){
          //初始化3D方法
          this.init();
          //加载模型方法
          this.loadModel();
          //初始化渲染方法
          this.animate();
          this.raycaster = new Raycaster();
          this.mouse = new Vector2();
        }
      },
      // immediate:true,
      deep:true
    },
    // 监听模型名称是否发生变化
    pathName(){
      // this.jsonData=JSON.parse(JSON.stringify(this.objJson));
      //判断是否有模型路径和数据，为true初始化
        if(this.objJson&&this.pathName){
         //初始化3D方法
          this.init();
          //加载模型方法
          this.loadModel();
          //初始化渲染方法
          this.animate();
          this.raycaster = new Raycaster();
          this.mouse = new Vector2();
        }
    }
  },
  //销毁之前清空3D方法
  beforeDestroy (){
    try {
      this.removeScene();
    } catch (error) {
      
    }
  },
  methods:{
    //清空3D方法
    removeScene () {
      window.removeEventListener("resize",this.unResize);
      window.removeEventListener("mousewheel",this.unmousewheel);
      this.clearScene();
      this.renderer.renderLists.dispose();
      this.renderer.dispose();
      this.renderer.forceContextLoss();
      this.renderer.domElement = null;
      this.renderer.content = null;
      this.renderer = null;
      THREE.Cache.clear();
      var box=document.getElementsByTagName('canvas');
      box[0].remove();
      this.camera=null;
      this.scene=null;
      this.container=null;
      this.controls=null;
    },

    clearCache (item) {
      try {
        item.geometry.dispose();
        item.material.dispose();
      } catch (error) {
        
      }
      
    },

    clearScene()  {
      this.removeObj(this.scene);
    },

    removeObj (obj){
      let arr = obj.children.filter(x => x);
      arr.forEach(item => {
        if (item.children.length) {
          this.removeObj(item);
        } else {
          this.clearCache(item);
          item.clear();
        }
      });
      obj.clear();
      arr = null;
    },
    //初始化3D
    init(){
      //如果有场景，先清除，防止内存溢出，然后再重新加载
      if(this.scene){
        this.removeScene();
      }
      let camera=null;
      let scene=null;
      let renderer=null;
      let container=null;
      let controls=null;
      //获取threeDiv dom节点
      container = this.$refs.threeDiv;
      //把dom节点放在body上
      document.body.appendChild( container );
      //创建一个一个视角
      camera = new PerspectiveCamera( this.fov, window.innerWidth / window.innerHeight, this.near, this.far );
      //设置视角离原点的位置（眼睛距离模型的距离） 
      camera.position.set( -30, 30, 40 );
      // scene 创建一个场景
      scene = new THREE.Scene();
      //创建一个环境灯光 1.灯光的颜色 2.灯光的亮度
      var ambientLight = new THREE.AmbientLight( 0xd1d1d1,1 );
      //把灯光放在场景上面
      scene.add( ambientLight );
      //创建一个点灯光
      var pointLight = new THREE.PointLight( 0xffffff, 1 );
      //设置点光灯的位置
      pointLight.position.set(1198,1298,-1262.669) 
      //给模型添加灯光
      camera.add( pointLight );
      //把视角放入环境
      scene.add( camera );
      // 添加操作器       创建一个webgl对象  设置透明
      renderer = new THREE.WebGLRenderer({antialias: true,alpha: true,logarithmicDepthBuffer:true});
      // 设置颜色
      renderer.setClearColor(0xffffff,0);
      // 设置分辨率
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.outputEncoding = THREE.sRGBEncoding;
      // 设置渲染尺寸
      renderer.setSize( window.innerWidth, window.innerHeight );
      container.appendChild( renderer.domElement );
      //创建控制器
      controls = new OrbitControls( camera ,renderer.domElement );
      //是否可以缩放 
      controls.enableRotate =true; //启用旋转
      controls.enablePan = true; //启用平移
      controls.enableZoom =true;//启用缩放
      //设置相机距离原点的最远距离 
      controls.minDistance = 700; 
      //设置相机距离原点的最远距离 
      controls.maxDistance = 700; 
      //是否开启右键拖拽 
      controls.enablePan = true;
      //监听鼠标滚轮事件
      window.addEventListener('mousewheel', this.render);
      // 自适应监听
      window.addEventListener( 'resize', this.resize, false );
      this.camera=camera;
      this.scene=scene;
      this.renderer=renderer;
      this.container=container;
      this.controls=controls;
    },
    //鼠标滚轮事件
    render(e){
      e.stopPropagation();
      let fov=this.fov;
      let near=this.near;
      let far=this.far;
      if (e.wheelDelta) { //判断浏览器IE，谷歌滑轮事件
        if (e.wheelDelta > 0) { //当滑轮向上滚动时
          fov -= (near < fov ? 1 : 0);
        }
        if (e.wheelDelta < 0) { //当滑轮向下滚动时
          fov += (fov < far ? 1 : 0);
        }
      } else if (e.detail) { //Firefox滑轮事件
        if (e.detail > 0) { //当滑轮向上滚动时
          fov -= 1;
        }
        if (e.detail < 0) { //当滑轮向下滚动时
          fov += 1;
        }
      }
      this.fov=fov;
      this.near=near;
      this.far=far;
      //改变fov值，并更新场景的渲染
      this.camera.fov = fov;
      this.camera.updateProjectionMatrix();
      this.renderer.render(this.scene, this.camera);
    },
    //自适应监听事件
    resize(){
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize( window.innerWidth, window.innerHeight );
	  },
    //模型加载
    loadModel(){
      let self=this;
      let path=this.path;
      let pathName=this.pathName;
      let scene=this.scene;
      //加载glb模型
      var glbLoad=new GLTFLoader().setPath(path).load(pathName + '.glb', function(gltf){
          //gltf.scene所有的模型
          let object=gltf.scene;
          if(self.pathName=="shelf-1-A"){
            object.scale.set(0.03,0.03,0.03);//网格模型缩放
          }else if(self.pathName=="shelf-1-B"){
            object.scale.set(0.07,0.07,0.07);//网格模型缩放
          }else{
              object.scale.set(0.09,0.09,0.09);//网格模型缩放
          }
          //设置模型的中心点
          const box = new THREE.Box3().setFromObject(object);
          // 返回包围盒的宽度，高度，和深度
          // 返回包围盒的中心点
          const center = box.getCenter(new THREE.Vector3());
          object.position.x += object.position.x - center.x;
          object.position.y += object.position.y - center.y; 
          object.position.z += object.position.z - center.z;
          let obj=[];
          //把二维数组组装成一维数组
          for(var oI=0;oI<object.children.length;oI++){
            obj.push(...object.children[oI].children);
          }
          //把模型名称中有-的提取出来添加到数组中
          let pailArr=[];
          for(var i=0;i<obj.length;i++){
            if(obj[i].name.indexOf("-")!=-1){
              obj[i].visible=false;
              pailArr.push(obj[i]);
            }
          }
          //合并第一层和第二层的数据 第一层是库区，第二层是垛位
          let json1=[]
          self.jsonData.items.map(item=>{
            for(var jI=0;jI<item.children.length;jI++){
              let itemJson={
                rowNo:item.rowNo,
                warehouseId:item.warehouseId,
                warehouseName:item.warehouseName,
                hallId:item.hallId,
                hallName:item.hallName
              }
              Object.assign(itemJson,item.children[jI])
              json1.push(itemJson);
            }
          })
          //通过循环的方式利用模型身上visible属性进行显示隐藏
          pailArr.map(item=>{
            for(var i=json1.length-1;i>=0;i--){
              //rowNo是位置编号 locationNo层级，桶是按照顺时针排序
              let nameStr=`${json1[i].rowNo}-${json1[i].locationNo}`.split("-");
              let itemNameArr=item.name.split("-");
              if(itemNameArr[0]==nameStr[0]&&itemNameArr[1]==nameStr[1]){
                if(!item.visible){
                  item.visible=true;
                  item.jsonData=json1[i];
                  json1.splice(i,1);
                  break;
                }
              }
            }
          })

          //将模型添加至场景
          scene.add(object)

      }, self.onProgress, self.onError )
			
	  },
    //glb模型load之后的方法
    onProgress(xhr){
      
    },
    //glb模型失败的方法
    onError(){

    },
    //鼠标点击事件
    onMouseClick( event ) {
        //判断selectedData中的length，如果有清除children,children中是线的对象
        for(var sI=this.selectedData.length-1;sI>=0;sI--){
          this.selectedData[sI].children=[];
          this.selectedData[sI].scale.set(1,1,1);
          this.selectedData.splice(sI,1);

        }
        let mouse=this.mouse;
        let raycaster=this.raycaster;
        //获取canvas的dom节点
        let canvas=document.getElementsByTagName('canvas')[0];
        //通过鼠标点击的位置计算出raycaster所需要的点的位置，以屏幕中心为原点，值的范围为-1到1.
        mouse.x = ((event.clientX - document.body.scrollLeft) / canvas.clientWidth ) * 2 - 1;
        mouse.y = - ( (event.clientY - document.body.scrollTop) / canvas.clientHeight ) * 2 + 1;
        // 通过鼠标点的位置和当前相机的矩阵计算出raycaster
        raycaster.setFromCamera( mouse, this.camera );
        // 获取raycaster直线和所有模型相交的数组集合
        var intersects = raycaster.intersectObjects( this.scene.children);
        //intersects是否有数据
        if(intersects&&intersects.length){
          //获取第0个元素
          let senceObj=intersects[ 0 ].object;
          //通过name中的元素判断点击的是桶还是架子，带有&&的是架子，-的是桶，都需要把赋值模型的名称方便判断
          var pName=null;
          if(senceObj.name.indexOf("&&")!=-1){
            pName=senceObj.name.substr(0,senceObj.name.indexOf("&"))+"&&";
          }else if(senceObj.name.indexOf("-")!=-1){
            pName=senceObj.name.substr(0,senceObj.name.indexOf("-"))+"&&";
          }else{
            // this.composer=null;
          }
          //获取scene.children[2].children的对象，这是一个组，里面包含了所有的模型
          var sence=this.scene.children[2].children;
          for(var i=0;i<sence.length;i++){
            //模型是n层机构，现在的模型是三层结构
            for(var j=0;j<sence[i].children.length;j++){
                //判断name模型是否是架子
                if(sence[i].children[j].name==pName){
                  //如果是架子，判断当前的模型的type是不是Object3D这个属性，只有Mesh对象的才可以添加边框，Object3D对象不可以添加
                  if(sence[i].children[j].type=="Object3D"){
                    for(var cI=0;cI<sence[i].children[j].children.length;cI++){
                      //创建一个白色材质
                      let edgesMtl =  new THREE.LineBasicMaterial({color: 0xffffff,linewidth: 1});
                      //创建一条线，需要传两个参数，一个是Mesh对象中的geometry，一个是材质
                      let cubeLine = new THREE.LineSegments(sence[i].children[j].children[cI].geometry, edgesMtl);
                      //把线放到Mesh对象上面
                      sence[i].children[j].children[cI].add(cubeLine);
                      //设置Mesh的宽度的倍数为1.2，让显示的更加明显
                      sence[i].children[j].children[cI].scale.set(1.2,1,1);
                      //把当前的Mesh对象添加到selectedData数组中，让其在下次点击的时候清空children;
                      this.selectedData.push(sence[i].children[j].children[cI]);
                    }
                  }
                  //返回一个方法，把pName架子的名称返回回去，方便判断
                  this.$emit('threeClick',pName);
                return;
              }
            }
          }
        }else{
         this.$emit('threeClick');
        }

      },
      animate() {
        var clock = new THREE.Clock();
        let delta = clock.getDelta();
        //更新场景
        this.controls.update(delta);
        //渲染下一帧的时候回调用
        requestAnimationFrame( this.animate );
        //使用渲染器，通过相机将场景渲染出来
        this.renderer.render( this.scene, this.camera );

        
    }

  },
  mounted(){
    if(Detector.webgl){
       window.addEventListener( 'click', this.onMouseClick, false );
    }else{
        alert('浏览器不支持查看');
    }
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
.threeDiv{
  position:absolute;
  z-index: 100;
  width:100%;
  height:100%;
  top:0px;
}
body {
	font-family: Monospace;
	color: #fff;
	margin: 0px;
	overflow: hidden;
	background-color: #EAEAEA;
	background-size: cover;
	-moz-background-size: cover;
	-webkit-background-size: cover;
	-o-background-size: cover;
}		
html,body{
	margin: 0;
	padding: 0;
}
.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 120px;
  margin-left: -60px;
  margin-top: -60px;
  overflow: hidden;
  -webkit-transform-origin: 60px 60px;
  -moz-transform-origin: 60px 60px;
  -o-transform-origin: 60px 60px;
  -ms-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
  -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
  mix-blend-mode: hard-light;
  opacity: 0.8;
}
.loader-line-mask .loader-line {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.loader-line-mask.one {
  -webkit-animation: rotate 2s infinite linear;
  -moz-animation: rotate 2s infinite linear;
  -o-animation: rotate 2s infinite linear;
  animation: rotate 2s infinite linear;
}
.loader-line-mask.one .loader-line {
  box-shadow: inset 0 0 0 8px #77C170;
}
.loader-line-mask.two {
  -webkit-animation: rotate 1.8s 0.5s infinite linear;
  -moz-animation: rotate 1.8s 0.5s infinite linear;
  -o-animation: rotate 1.8s 0.5s infinite linear;
  animation: rotate 1.8s 0.5s infinite linear;
}
.loader-line-mask.two .loader-line {
  box-shadow: inset 0 0 0 8px #F25F5C;
}
.loader-line-mask.three {
  -webkit-animation: rotate 2s 1s infinite linear;
  -moz-animation: rotate 2s 1s infinite linear;
  -o-animation: rotate 2s 1s infinite linear;
  animation: rotate 2s 1s infinite linear;
}
.loader-line-mask.three .loader-line {
  box-shadow: inset 0 0 0 8px #FFE066;
}
.loader-line-mask.four {
  -webkit-animation: rotate 1.8s 1.5s infinite linear;
  -moz-animation: rotate 1.8s 1.5s infinite linear;
  -o-animation: rotate 1.8s 1.5s infinite linear;
  animation: rotate 1.8s 1.5s infinite linear;
}
.loader-line-mask.four .loader-line {
  box-shadow: inset 0 0 0 8px #247BA0;
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes rotate{ 0% { -webkit-transform: rotate(0deg);} 100% { -webkit-transform: rotate(360deg);}}
@-moz-keyframes rotate{ 0% { -moz-transform: rotate(0deg);} 100% { -moz-transform: rotate(360deg);}}
@-o-keyframes rotate{ 0% { -o-transform: rotate(0deg);} 100% { -o-transform: rotate(360deg);}}
@keyframes rotate{ 0% {-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);transform: rotate(0deg);} 100% {-webkit-transform: rotate(360deg);-moz-transform: rotate(360deg);-ms-transform: rotate(360deg);transform: rotate(360deg);};
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes rotate{ 0% { -webkit-transform: rotate(0deg);} 100% { -webkit-transform: rotate(360deg);}}
@-moz-keyframes rotate{ 0% { -moz-transform: rotate(0deg);} 100% { -moz-transform: rotate(360deg);}}
@-o-keyframes rotate{ 0% { -o-transform: rotate(0deg);} 100% { -o-transform: rotate(360deg);}}
@keyframes rotate{ 0% {-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);transform: rotate(0deg);} 100% {-webkit-transform: rotate(360deg);-moz-transform: rotate(360deg);-ms-transform: rotate(360deg);transform: rotate(360deg);};
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
@-moz-keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
@-o-keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
@keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;};
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
@-moz-keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
@-o-keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;}}
@keyframes fade{ 0% { opacity: 1;} 50% { opacity: 0.25;};
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
@-moz-keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
@-o-keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
@keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;};
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
@-moz-keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
@-o-keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}}
@keyframes fade-in{ 0% { opacity: 0;} 100% { opacity: 1;}};
</style>
