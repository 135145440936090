/** 2018/2/23 17:42
 *  作者:周志豪
 *  功能:封装请求方法
 *  备注:
 */
 import axios from 'axios'
 import qs from 'qs'
 import util from "@/utils/util"
 import Vue from "vue";
 let isRelogin = true;
// axios.defaults.baseURL = '/LG-BRANCH'
 //axios默认参数配置
 axios.defaults.timeout = 1000 * 65;
 let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJGODJDNEZGRTlBNUY0OTc3OTAwQjlFREM0MERCQkRCOSIsImlzcyI6IkFVVEguU0VSVkVSIiwiaWF0IjoxNjU4MjE0NzA3LCJhdWQiOiJBVVRILkNMSUVOVCIsIm5iZiI6MTY1ODIxNDcwNywic3ViIjoie1wiYnVzaW5lc3NJRHNcIjp7fSxcImNvbXBhbnlDb2RlXCI6XCJTR0FJXCIsXCJjb21wYW55SWRcIjpcIkdRVFpQVFNaWEdTXCIsXCJjb21wYW55TmFtZVwiOlwi5YyX5Lqs6aaW6ZKi6Ieq5Yqo5YyW5L-h5oGv5oqA5pyv5pyJ6ZmQ5YWs5Y-4XCIsXCJpZGVudGl0eUlkXCI6XCJJVkdTdUpDRjJkRytrUUhGbXFjL3hEaDAwS3Y0d2hkdFwiLFwibG9naW5Hcm91cFwiOlwiQ1wiLFwibG9naW5JZFwiOlwid2FuZ2ptNTY3OFwiLFwibG9naW5TaGlmdFwiOlwiMVwiLFwibW9iaWxlUGhvbmVcIjpcIjE4MDMxMzE1MTY0XCIsXCJvcmdGdWxsTmFtZVwiOlwiTUVT6L-B6aG65YiG6YOoXCIsXCJvcmdJZFwiOlwiUVNGQk1FU1FTRkJcIixcIm9yZ1Nob3J0TmFtZVwiOlwiTUVT6L-B6aG65YiG6YOoXCIsXCJzZXhcIjpcIjFcIixcInVzZXJEdXR5XCI6XCJEMDAxMVwiLFwidXNlckZ1bGxOYW1lUHlcIjpcIndhbmdqbTU2NzhcIixcInVzZXJHcm91cHNcIjpcIkFVVEgwMDAxLEcxMDM2OTk5OTksRzE2NDg4MDAyMDg5NDdcIixcInVzZXJOYW1lQ25cIjpcIueOi-ess-WQjVwiLFwidXNlclJvbGVzXCI6XCJBVVRIMDAwMSxSMTAzNjk5OTk5LFIxNjQ4ODAwMjQwODg5XCIsXCJ1c2VyU2lkXCI6MTM1NzEsXCJ1c2VyVHlwZVwiOlwiMFwiLFwidmFsaWRGbGFnXCI6XCIxXCJ9In0.SEWSSJ4mBP3S6E1_FCsLUPn79VwJLouJYtr0oWu0n8g'
 //添加一个请求拦截器
 axios.interceptors.request.use(function (config) {
  //  let authToken = util.getCookie('auth-token');

  //  if(authToken){
  //   config.headers.common['Authorization'] = 'Bearer ' + util.getCookie('auth-token');
  //  }
   config.headers.Authorization = token;
   
  //  config.headers['auth-token'] = (util.getCookie('auth-token') && util.getCookie('auth-token') !== '') ? util.getCookie('auth-token') : '123';
    //  if (config.apiType === 'notice' || config.apiType === 'form') {
    //   //  config.data = qs.stringify(config.data);
    //   // config.responseType = 'blob'
    //    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    //  } else {
    //    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    //  }
    //  if(config.apiType == "cms"){
    //    config.headers['Cache-Control'] = 'no-cache';
    //  }
    
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
   return config;
 }, function (error) {
   return Promise.reject(error);
 });
 //添加一个响应拦截器
 axios.interceptors.response.use(function (res) {
   let result;
  //  switch (res.status) {
  //    case 200:
  //      let data = res.data;
  //      if(res.headers['content-type'] == 'application/octet-stream;charset=UTF-8'){//文件流下载
  //        result = res
  //        return result;
  //      }
  //      if(data.rows && data.rows.respCode === "0000"){
  //        result = data;
  //      } else if(data.retCode == "0000000" || data.retCode == "2000000"){
  //        result = data;
  //      }else {
  //       //  debugger;
  //       // this.$message({
  //       //     message: res.data.retDesc,
  //       //     type: 'warning'
  //       // });
  //       alert(res.data.retDesc);
  //      }
  //      break;
  //  }
  // debugger;
   //在这里对返回的数据进行处理
   return res;
 }, function (error) {
   if (error.response) {
     switch (error.response.status) {
       case 401:
         let data = error.response.data;
         util.removeCookie('auth-token'); // 清除cookie登录票据
         Vue.prototype.$alert('很抱歉，您未登录或者登录信息过期，请重新登录！', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            window.location.href ="http://10.168.60.31/problem/#/login";
          }
        });
         break;
       case 404:
         console.log("404:找不到文件");
         break;
       case 500:
         console.log("500:服务器出错");
         break;
     }
   }
   return error;
 });
 
 export default function request(url, option) {
   const httpUrl = url;
   let reqUrl = '';
   if(option.body.method === 'GET'){
     reqUrl = httpUrl + '?' + (option.body.data ? qs.stringify(option.body.data) :'');
   }else{
     reqUrl = httpUrl;
   }
   const defaultOptions = {
     method: 'POST',
     url: reqUrl,
     apiType: option.apiType
   };
   const newOptions = { ...defaultOptions, ...option.body };
   return axios({
     ...newOptions
   }).then(res => {
       return res
   })
 }
 