import request from '@/utils/request'
// import mock from '@/utils/mock'
// var Mock = require('mockjs')
// http://192.168.100.108:8080/qlgl-app/chartSlotInfo/getSlotInfo.action
//查询方法
export function getSlotInfo(url, data) {
  // const formData = new FormData()
 
  const params = new URLSearchParams();
  for (const key in data) {
    params.append(key, data[key])
  }
  return request(url,{
    apiType: 'form',
    body:{
      data: params,
      method: 'POST',
    },
  })
}
//登陆
export function login(url, data) {
  // const formData = new FormData()
  // for (const key in data) {
  //   formData.append(key, data[key])
  // }
  const params = new URLSearchParams();
  params.append('username', 'admin')
  params.append('password', "Aa12345678")
  return request(url,{
    apiType: 'form',
    body:{
      data: params,
      method: 'POST',
    },
  })
}
export async function queryApi(url, params) {
  return request(url, {
    body: {
      data: params
    }
  });
}
// /ecm-app/ecmsqprespromager/selectSqSummary.action
export function selectSqSummary(url,params){
  return request(url, {
    body: {
      data: params
    }
  });
}





