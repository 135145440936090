const jsonData={
    "meta":{
        "success":true,
        "message":"操作成功!"
    },
    "items":[
        {
            "warehouseId":"1",
            "warehouseName":"冷轧漆料库",
            "hallId":"A0-01",
            "hallName":"A跨",
            "rowNo":"1",
            "children":[
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/2",
                    "locationNo":1,
                    "batchId":"PMW10069-S",
                    "bucketId":"003",
                    "coatDesc":"SC 450HQ 4449 白灰面漆（1535）WG",
                    "coatCode":"11C0802",
                    "inWarehouseDt":"2022-06-23 13:43:38",
                    "netWeight":203,
                    "dateOfPro":"2021-12-22",
                    "coatSellDesc":"立邦",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"白、象牙",
                    "coatColorDesc":"白灰",
                    "coatingRate":"345"
                },
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/2",
                    "batchId":"PMW10069-S",
                    "bucketId":"002",
                    "locationNo":2,
                    "coatDesc":"SC 450HQ 4449 白灰面漆（1535）WG",
                    "coatCode":"11C0802",
                    "inWarehouseDt":"2022-07-05 14:23:34",
                    "netWeight":202,
                    "dateOfPro":"2021-12-21",
                    "coatSellDesc":"立邦",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"白、象牙",
                    "coatColorDesc":"白灰",
                    "coatingRate":"345"
                },
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/2",
                    "batchId":"PMW10068-S",
                    "bucketId":"11",
                    "coatDesc":"SC 450HQ 8B62 棕色面漆（1833）",
                    "coatCode":"11C0608",
                    "inWarehouseDt":"2022-07-08 09:30:20",
                    "netWeight":120,
                    "locationNo":3,
                    "dateOfPro":"2021-12-20",
                    "coatSellDesc":"立邦",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"棕、褐、咖啡、砖红",
                    "coatColorDesc":"棕色8B62",
                    "coatingRate":"370",
                    "a":"200",
                    "b":"200",
                    "l":"100"
                },
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/2",
                    "batchId":"PMW10068-S",
                    "bucketId":"14",
                    "coatDesc":"SC 450HQ 8B62 棕色面漆（1833）",
                    "coatCode":"11C0608",
                    "locationNo":1,
                    "inWarehouseDt":"2022-07-05 14:06:03",
                    "netWeight":101,
                    "dateOfPro":"2021-12-20",
                    "coatSellDesc":"立邦1",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"棕、褐、咖啡、砖红",
                    "coatColorDesc":"棕色8B62",
                    "coatingRate":"370",
                    "a":"200",
                    "b":"200",
                    "l":"100"
                }
            ]
        },
        {
            "warehouseId":"1",
            "warehouseName":"冷轧漆料库",
            "hallId":"A0-02",
            "hallName":"A跨",
            "rowNo":"2",
            "children":[
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/3",
                    "batchId":"PMW10068-S",
                    "bucketId":"3",
                    "locationNo":1,
                    "coatDesc":"SC 450HQ 8B62 棕色面漆（1833）",
                    "coatCode":"11C0608",
                    "inWarehouseDt":"2022-07-08 09:30:20",
                    "netWeight":50,
                    "dateOfPro":"2021-12-21",
                    "coatSellDesc":"立邦",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"棕、褐、咖啡、砖红",
                    "coatColorDesc":"棕色8B62",
                    "coatingRate":"370",
                    "a":"200",
                    "b":"200",
                    "l":"100"
                },
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/3",
                    "batchId":"PMW10069-S",
                    "bucketId":"005",
                    "locationNo":1,
                    "coatDesc":"SC 450HQ 4449 白灰面漆（1535）WG",
                    "coatCode":"11C0802",
                    "inWarehouseDt":"2022-07-08 09:30:20",
                    "netWeight":205,
                    "dateOfPro":"2021-12-24",
                    "coatSellDesc":"立邦",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"白、象牙",
                    "coatColorDesc":"白灰",
                    "coatingRate":"345"
                },
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/3",
                    "batchId":"PMW10069-S",
                    "bucketId":"004",
                    "locationNo":3,
                    "coatDesc":"SC 450HQ 4449 白灰面漆（1535）WG",
                    "coatCode":"11C0802",
                    "inWarehouseDt":"2022-07-08 09:30:20",
                    "netWeight":204,
                    "dateOfPro":"2021-12-23",
                    "coatSellDesc":"立邦",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"白、象牙",
                    "coatColorDesc":"白灰",
                    "coatingRate":"345"
                }
            ]
        }
    ],
    "success":true
}
const jsonData1={
    "meta":{
        "success":true,
        "message":"操作成功!"
    },
    "items":[
        {
            "warehouseId":"1",
            "warehouseName":"冷轧漆料库",
            "hallId":"A0-01",
            "hallName":"B跨",
            "rowNo":"1",
            "children":[
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/2",
                    "locationNo":2,
                    "batchId":"PMW10069-S",
                    "bucketId":"003",
                    "coatDesc":"SC 450HQ 4449 白灰面漆（1535）WG",
                    "coatCode":"11C0802",
                    "inWarehouseDt":"2022-06-23 13:43:38",
                    "netWeight":203,
                    "dateOfPro":"2021-12-22",
                    "coatSellDesc":"立邦",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"白、象牙",
                    "coatColorDesc":"白灰",
                    "coatingRate":"345"
                },
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/2",
                    "batchId":"PMW10069-S",
                    "bucketId":"002",
                    "locationNo":2,
                    "coatDesc":"SC 450HQ 4449 白灰面漆（1535）WG",
                    "coatCode":"11C0802",
                    "inWarehouseDt":"2022-07-05 14:23:34",
                    "netWeight":202,
                    "dateOfPro":"2021-12-21",
                    "coatSellDesc":"立邦",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"白、象牙",
                    "coatColorDesc":"白灰",
                    "coatingRate":"345"
                },
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/2",
                    "batchId":"PMW10068-S",
                    "bucketId":"11",
                    "coatDesc":"SC 450HQ 8B62 棕色面漆（1833）",
                    "coatCode":"11C0608",
                    "inWarehouseDt":"2022-07-08 09:30:20",
                    "netWeight":120,
                    "locationNo":3,
                    "dateOfPro":"2021-12-20",
                    "coatSellDesc":"立邦",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"棕、褐、咖啡、砖红",
                    "coatColorDesc":"棕色8B62",
                    "coatingRate":"370",
                    "a":"200",
                    "b":"200",
                    "l":"100"
                },
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/2",
                    "batchId":"PMW10068-S",
                    "bucketId":"14",
                    "coatDesc":"SC 450HQ 8B62 棕色面漆（1833）",
                    "coatCode":"11C0608",
                    "locationNo":1,
                    "inWarehouseDt":"2022-07-05 14:06:03",
                    "netWeight":101,
                    "dateOfPro":"2021-12-20",
                    "coatSellDesc":"立邦1",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"棕、褐、咖啡、砖红",
                    "coatColorDesc":"棕色8B62",
                    "coatingRate":"370",
                    "a":"200",
                    "b":"200",
                    "l":"100"
                }
            ]
        },
        {
            "warehouseId":"1",
            "warehouseName":"冷轧漆料库",
            "hallId":"B0-02",
            "hallName":"A跨",
            "rowNo":"2",
            "children":[
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/3",
                    "batchId":"PMW10068-S",
                    "bucketId":"3",
                    "locationNo":3,
                    "coatDesc":"SC 450HQ 8B62 棕色面漆（1833）",
                    "coatCode":"11C0608",
                    "inWarehouseDt":"2022-07-08 09:30:20",
                    "netWeight":50,
                    "dateOfPro":"2021-12-21",
                    "coatSellDesc":"立邦",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"棕、褐、咖啡、砖红",
                    "coatColorDesc":"棕色8B62",
                    "coatingRate":"370",
                    "a":"200",
                    "b":"200",
                    "l":"100"
                },
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/3",
                    "batchId":"PMW10069-S",
                    "bucketId":"005",
                    "locationNo":3,
                    "coatDesc":"SC 450HQ 4449 白灰面漆（1535）WG",
                    "coatCode":"11C0802",
                    "inWarehouseDt":"2022-07-08 09:30:20",
                    "netWeight":205,
                    "dateOfPro":"2021-12-24",
                    "coatSellDesc":"立邦",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"白、象牙",
                    "coatColorDesc":"白灰",
                    "coatingRate":"345"
                },
                {
                    "version":1,
                    "createdDt":"2022-07-13 10:22:14",
                    "slotId":"A0-01-001/3",
                    "batchId":"PMW10069-S",
                    "bucketId":"004",
                    "locationNo":3,
                    "coatDesc":"SC 450HQ 4449 白灰面漆（1535）WG",
                    "coatCode":"11C0802",
                    "inWarehouseDt":"2022-07-08 09:30:20",
                    "netWeight":204,
                    "dateOfPro":"2021-12-23",
                    "coatSellDesc":"立邦",
                    "coatTypeDesc":"PE常规聚酯",
                    "paintUseCode":"C",
                    "paintColorSystemDesc":"白、象牙",
                    "coatColorDesc":"白灰",
                    "coatingRate":"345"
                }
            ]
        }
    ],
    "success":true
}
export {
    jsonData,
    jsonData1
}