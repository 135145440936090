
let newUtil = null;
  class Util {
    /**
     * @param pcodes: 查询字典表入参集合，em：['peb_order_source']
     * @callback: 带有处理后的总体数据
     * */
    async getDic(pcodes, callback) {
      let res = await Util.getCodeData(pcodes);
      //在这里把后端返回的res处理一下，尽量把原有的pcode摘出来
      res = Util.handleDic(res);
      callback && callback(res);
      return res;
    }

    static getCodeData(pcodes) {
      return new Promise((resolve, reject) => {
        // let key = 'pcode';
        // switch (type) {
        //   case 'peb':
        //     key = 'code';
        //     break;
        //   case 'fsc':
        //     key = 'pCode';
        //     break;
        // }
        queryApi(PES_URL['pes_dicConfigQueryData'], {configKeys: pcodes})
          .then(res => {
            resolve(res)
          });

      })
    }
    //处理字典表数据
    static handleDic(res) {
      let [result, typeAndResult] = [{}, []];
      if (res.data) {
        let arr = JSON.parse(JSON.stringify(res.data));
        _.each(arr, (val, k) => {
          result[k.replace('_', '&').split('&')[1].toUpperCase()] = val;
          typeAndResult.push({
            type: k.replace('_', '&').split('&')[0],
            code: k.replace('_', '&').split('&')[1].toUpperCase(),
            list: val
          })
        })
      }
      return Object.assign(res, { result, typeAndResult });
    }

    /**
     * 数据适配
     * param data：需要适配的数据源（Array，Object）
     * param arr1：需要替换的key值集合（Array）
     * param arr2：替换后的key值集合（Array）
     * param savaAll：是否同时保留替换前后的key（布尔值）
     * */
    replaceObjKeys(data, arr1, arr2, saveAll) {
      //多组对象
      if (Array.isArray(data)) {
        var arr = data.map(item => {
          var obj = {};
          _.forEach(arr1, (val, i) => {
            obj[arr2[i]] = item[val];

          })
          if (saveAll) {
            return _.extend(obj, item);
          }
          return obj;
        })
        return JSON.parse(JSON.stringify(arr));
      }
      _.forEach(arr1, (item, i) => {
        data[arr2[i]] = data[item];
        delete data[item];
      })
      return _.extend({}, data);
    }
    //四则运算化成整数计算方法
    floatCalc(a, b) {
      a = a + '', b = b + '';
      var aNum = a.indexOf('.'),
        bNum = b.indexOf('.'),
        aSum,
        bSum,
        resultNum,
        inta,
        intb;

      aSum = aNum < 0 ? 0 : a.split('.')[1].length;
      bSum = bNum < 0 ? 0 : b.split('.')[1].length;
      resultNum = aSum > bSum ? aSum : bSum;

      inta = aNum < 0 ? Number(a + (Math.pow(10, resultNum) + '').replace('1', '')) : (function () {
        a = a.replace('.', '');
        a = resultNum == aSum ? a : a + (Math.pow(10, resultNum - aSum) + '').replace('1', '');
        return Number(a);
      }())

      intb = bNum < 0 ? Number(b + (Math.pow(10, resultNum) + '').replace('1', '')) : (function () {
        b = b.replace('.', '');
        b = resultNum == bSum ? b : b + (Math.pow(10, resultNum - bSum) + '').replace('1', '');
        return Number(b);
      }())
      return {
        a: inta,
        b: intb,
        num: resultNum
      };
    }

    // 限制只能输入整数、小数且最多两位小数
    limitToNum(data){
      let num = data.replace(/[^\d.]/g, "").
      //只允许一个小数点
      replace(/^\./g, "").replace(/\.{2,}/g, ".").
      //只能输入小数点后两位
      replace(".", "$#$").replace(/\./g, "").replace("$#$", ".").replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      return num;
    }

    //保留两位小数
    rounding(mVal){
      mVal=(!mVal?0:mVal);
      mVal=String(mVal);
      mVal=(mVal=='[]'?0:mVal);
      if(mVal.indexOf('.')==-1){
        mVal+='.00';
      }else{
        let iFra = 0;//小数部分
        let iInt = 0;//整数部分
        let mValArr=mVal.split(".");
        iInt=mValArr[0];
        let iFraArr=mValArr[1].split('');
        if(iFraArr.length>2){
          if(iFraArr[2]-0>4){
            if(iFraArr[1]==9){
              if(iFraArr[0]==9){
                iFra='00';
                iInt=iInt-0+1;
              }else{
                iFra=''+(iFra[0]-0+1)+'0';
              }
            }else{
              iFra=''+iFra[0]+(iFra[1]-0+1);
            }
          }else{
            iFra=iFraArr[0]+iFraArr[1];
          }
        }else if(iFraArr.length==1){
          iFra=mValArr[1]+'0';
        }else{
          iFra=mValArr[1];
        }
        mVal=iInt+'.'+iFra
      }
      return mVal;
    }
    /**
     * 获取url后面的参数
     * @param name
     * @returns {string}
     */
    getQueryString(name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      let r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      let context = "";
      if (r != null) context = r[2];
      reg = null;
      r = null;
      return context == null || context == '' || context == 'undefined' ? '' : context;
    }
    getUrlString(key){
      let str = window.location.href;
      let theRequest = {};
      let index = str.indexOf('?');
      if(index!==-1){
        str = str.substr(index+1);
        if(str.indexOf('&')!==-1){
          let strs = [];
          strs = str.split("&");
          for(var i = 0; i < strs.length; i ++) {
            theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
          }
        }else{
          let strs = str.split("=");
          theRequest[strs[0]] = strs[1];
        }
      }
      return theRequest[key]
    }
    /**
     * cookie
     * @调用：setCookie('access_token', '123456', 1 * 24 * 60 * 60 * 1000);
     * @调用：getCookie('access_token');
     * @调用：removeCookie('access_token');
     */
    // 存储，可设置过期时间（毫秒）
    setCookie(key, value, expires,domain) {
      let exparam = '';
      if (expires) {
        let d = new Date();
        d.setTime(d.getTime() + expires);
        exparam = `; expires=${d.toGMTString()}`;
      } else {
        exparam = '';
      }
      domain = domain?domain:document.domain;
      document.cookie = `${key}=${value}${exparam}; path=/;domain=${domain}`;
    }
    // 取出
    getCookie(key) {
      let name = `${key}=`;
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        // let c = ca[i].trim(); // IE6、7、8不支持trim()方法
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
      }
      return '';
    }
    // 删除
    removeCookie(key,domain) {
      this.setCookie(key, '', -1,domain);
    }

    //aes加密
    Encrypt(word, keyStr, ivStr) {
      let key = keyStr ? keyStr : KEY;
      let iv = ivStr ? ivStr : IV;
      key = CryptoJS.enc.Utf8.parse(key);
      iv = CryptoJS.enc.Utf8.parse(iv);
      let srcs = CryptoJS.enc.Utf8.parse(word);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return encrypted.ciphertext.toString();
    }
  }
newUtil = new Util();
export default newUtil;
