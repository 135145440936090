<template>
  <div id="app">
    <div class="title">
      <div class="linear"></div>
      <div class="title-logo">
        <span>LOGO 系统名称</span>
      </div>
      <div class="title-list">
        <div class="list-header" @click="areaClick(1)">
          <div class="header-img" :class="libraryType==1?'clickHeader':''">
            库区一
          </div>
        </div>
        <div class="list-header" @click="areaClick(2)">
          <div class="header-img" :class="libraryType==2?'clickHeader':''">
            库区二
          </div>
        </div>
      </div>
      <div class="title-right">
      </div>
    </div>
    <div class="content">
      <div class="content-left">
        <div class="content-left-title">
          <span class="title-message">物料信息</span>
        </div>
        <div class="content-left-stacking" v-if="messageJson.length">
          <div class="stacking-number" v-if="messageJson[0].children&&messageJson[0].children.length>0">
            <span>垛位编号：{{messageJson[0].children[0].slotId}}</span>
          </div>
          <div class="product" v-for="(item,index) of childrenJson" :key="index" v-show="item.length">
            <div class="product-title">
              <span class="product-title-left">产品名称</span>
              <span class="product-title-right">供货商</span>
            </div>
            <div class="product-message"  v-for="(childrenItem,childrenIndex) of item" :key="childrenIndex">
              <span class="product-message-left">{{childrenItem.coatDesc}}</span>
              <span class="product-message-right">{{childrenItem.coatSellDesc}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-center">
        <threeModel :path="path" :pathName="pathName" :objJson="objJson" @threeClick="threeClick" ></threeModel>
        <div class="library">
          <div class="library-A" :class="classType=='A'?'library-color':''" @click="libClick('A')">A</div>
          <div class="library-B" :class="classType=='B'?'library-color':''" @click="libClick('B')">B</div>
          <div class="library-C" :class="classType=='C'?'library-color':''" @click="libClick('C')">C</div>
          <div class="library-D" :class="classType=='D'?'library-color':''" @click="libClick('D')">D</div>
          <div class="library-E" :class="classType=='E'?'library-color':''" @click="libClick('E')">E</div>
          <div class="library-F" :class="classType=='F'?'library-color':''" @click="libClick('F')">F</div>
        </div>
      </div>
    </div>
    <div class="bottom"></div>
  </div>
</template>
<script>
  import threeModel from '@/components/threeModel/shelf.vue'
  import { login,getSlotInfo } from '@/assets/api'
  import { jsonData } from '@/utils/shelf'
  import util from "@/utils/util"
  export default {
    name:"App",
    data(){
      return {
        //模型地址的绝对路径
        path:"/static/shelf/",
        //默认展示那个模型
        pathName:"",
        //后台接口返回的数据
        objJson:null,
        //渲染物料信息的数组
        messageJson:[],
        //物料中桶的信息
        childrenJson:[],
        //默认后台接口中hallId的值
        classType:'A',
        //默认库区是一库区
        libraryType:1,
      }
    },
    components:{threeModel},
    mounted(){
      // this.init();
      this.objJson=jsonData;
      this.pathName = 'shelf-1-A';
    },
    methods:{
      init(){
        let self=this;
        let authToken = util.getCookie('auth-token');
        if(!authToken){
           login('/ac/login/dologin.action',{username:'admin',password:"Aa12345678"}).then(res=>{
            util.setCookie('auth-token',res.data.data.token);
            // console.log(req);
            self.loadJson();
          })
        }else{
          self.loadJson();
        }
      },
      //调用接口方法
      loadJson(){
        let self=this;
        getSlotInfo('/chartSlotInfo/getSlotInfo.action',{'qm.warehouseId':this.libraryType,"qm.hallId":this.classType}).then(res=>{
          self.objJson=res.data;
        })
      },
      //子组件返回的方法 rowName 是架子的名字
      threeClick(rowName){
        if(rowName){
          //根据架子的名称用filter方法判断是后台接口数据中的那一条数据
          let rowNo=rowName.substr(0,rowName.indexOf("&"));
          this.messageJson=this.objJson.items.filter((item)=>{
            if(item.rowNo==rowNo){
              return true;
            }
          })
          if(this.messageJson.length>0){
            //默认是三层数据，所以定义一个二维数据，后台接口给的数据是一个一维数组没有经过排序，这里简单的排序
            this.childrenJson=[[],[],[]];
            this.messageJson[0].children.map(item=>{
              this.childrenJson[Number(item.locationNo)-1].push(item);
            })
          }
        }else{
          this.messageJson=[];
          this.childrenJson=[];
        }
        
        
      },
      //点击库区
      areaClick(type){
        this.libraryType=type;
      },
      //点击ABCDEF的时候需要调用接口和更改名称 type传的是ABCDEF
      libClick(type){
        this.classType=type;
        this.pathName=`shelf-${this.libraryType}-${type}`;
        // this.loadJson()
        this.objJson=jsonData;
      }
    }
  }
</script>

<style>
#app{
  width:100%;
  height:100%;
  margin:0px;
  padding: 0px;
}
.title{
  width:100%;
  height:50px;
  background: #030A2E;
  display:flex;
  position:relative;
  z-index: 999;
}
.linear{
  width:100%;
  height:22px;
  background-image: linear-gradient(rgba(3, 26, 56, 0), rgba(2, 30, 64, 1));
  position:absolute;
  bottom:0;
}
.title-logo{
  margin-left:24px;
  padding-top:12px;
  font-size:18px;
  flex:1;

}
.title-list{
 /* width:800px; */
 color:#1C73F5;
 font-size:18px;
 display: flex;
 align-self: center;
 flex:1;
 justify-content: center; 
}
.title-right{
  flex:1;
}
.list-header{
  line-height:50px; 
  width:234px;
  text-align: center;
  cursor:pointer;
}
.clickHeader{
  background: url("@/assets/images/bgTab.png") no-repeat;
  background-size: contain;
  height:59px;
  position:absolute;
  width:234px;
}
.content{
  width:100%;
  height:100%;
  background:#030A2E;
}
.content-left{
  width:240px;
  height:100%;
  padding-top:40px;
  /* background: rgba(19, 26, 46, 0.75); */
  background-image: linear-gradient(to right, rgba(19, 26, 46, 0.75) , rgba(0, 37, 83, 0.75));
  position:absolute;
  z-index: 2;
}
.content-left-title{
  width:210px;
  height:32px;
  padding-left:8px;
  background:url("@/assets/images/message.png") no-repeat;
  background-size: contain;
}
.title-message{
  margin-left: 10px;
  display: inline-block;
  font-size:14px;
  line-height:32px;
}
.stacking-number{
  margin-top:24px;
  font-size:14px;
  color:#00B6FF;
  margin-left:10px;
}
.product{
  font-size:14px;
  margin-top:6px;
}
.product-title{
  width:100%;
  height:32px;
  background-color:#1B3466;
  line-height: 32px;
  color:#7C9DD8;
  padding-left:10px;
  display:flex;
}
.product-title-left,.product-message-left{
  flex:2;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.product-title-right,.product-message-right{
  flex:1;
}
.product-message{
  width:100%;
  height:32px;
  line-height: 32px;
  color:#00B6FF;
  padding-left:10px;
  display:flex;
}
.library{
  width:284px;
  height:134px;
  position: fixed;
  background-color:#1D2B69;
  top:66px;
  right:40px;
  color:#5272AC;
  z-index: 999;
  
}
.library-A{
  width:268px;
  height: 16px;
  margin:8px;
  margin-bottom: 24px;
  text-align: center;
  border:1px dotted #5272AC;
  line-height: 16px;
  position:absolute;
  cursor:pointer;
}
.library-color{
  /* background:#1C78FF; */
  background-image: linear-gradient(#1E368C, #1C78FF);
  color:#fff;
  border:none;
}
.library-B{
  width:116px;
  height: 32px;
  text-align: center;
  border:1px dotted #5272AC;
  line-height: 32px;
  position:absolute;
  top:48px;
  left:8px;
  cursor:pointer;
}
.library-C{
  width:116px;
  height: 16px;
  text-align: center;
  border:1px dotted #5272AC;
  line-height: 16px;
  position:absolute;
  top:104px;
  left:8px;
  cursor:pointer;
}
.library-D{
  width:32px;
  height: 56px;
  text-align: center;
  border:1px dotted #5272AC;
  line-height: 56px;
  position:absolute;
  top:48px;
  left:148px;
  cursor:pointer;
}
.library-E{
  width:32px;
  height: 56px;
  text-align: center;
  border:1px dotted #5272AC;
  line-height: 56px;
  position:absolute;
  top:48px;
  right:48px;
  cursor:pointer;
}
.library-F{
  width:16px;
  height: 56px;
  text-align: center;
  border:1px dotted #5272AC;
  line-height: 56px;
  position:absolute;
  top:48px;
  right:8px;
  cursor:pointer;
}
.bottom{
  position:absolute;
   background:url("@/assets/images/bottom.png") no-repeat;
    width: 100%;
    height: 290px;
    bottom: 0px;
    background-size: cover;
}
</style>
